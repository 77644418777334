import { useEffect, useState } from "react";
import "./styles.css";

const CTAForm = (props) => {
  const [text, setText] = useState("SOLICITE UM ORÇAMENTO");
  const [link, setLink] = useState("#form");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    if (props.link !== undefined) setLink(props.link);
  }, [props.text, props.link]);

  return (
    <button id="ctaForm">
      <a href={link}>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CTAForm;
